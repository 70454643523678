import React, { ReactNode, useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Location, navigate, useLocation } from "@reach/router";
import { observer } from "mobx-react";
import { Footer } from "@components/LayoutFooter";
import { LayoutHeader } from "@components/LayoutHeader";
import { LayoutEventHeader } from "@components/LayoutHeader";
import { Seo } from "@components/Seo";
import { ToastProvider } from "@components/Toast";
import { useIntl } from "gatsby-plugin-intl";
import { TwoColumnLayout } from "@components/Layout";
import { theme, GlobalStyles } from "@styles";
import { useStores } from "@stores";
import PushService from "../../services/PushService";
import { Box } from "@components/Primitives";
import { useIsClient } from "../../utils/index";
import { PATHS } from '../../constants/nav-items'
import { getPathWithoutLangPrefix } from "../../utils/intlUtils";


const Content = styled.main`
  max-width: ${(props: { maxWidth?: string; theme: ITheme }) =>
    props.maxWidth || props.theme.constants.contentMaxWidth};
  margin: 0 auto;
  padding: 3rem 1rem;
`;

const Wrapper = styled.div`
  height: 100%;
  margin: auto;
`;

const Version = styled.div`
  display: none;
`;

export interface ILayout {
  heroImage?: string;
  heroContent?: ReactNode;
  secondaryNavItems?: INavItem[];
  maxWidth?: string;
  extraShortcuts?: any;
  afterHeader?: ReactNode;
  twoColumnLayout?: boolean;
  customLayout?: boolean;
}

const Layout: React.FC<ILayout> = observer(
  ({
    children,
    heroImage,
    heroContent,
    maxWidth,
    extraShortcuts,
    afterHeader,
    twoColumnLayout,
    customLayout,
  }) => {
    const { key } = useIsClient();
    const [currentHref, setCurrentHref] = useState('');
    const [pushInitialized, setPushInitialized] = useState(false);
    const location = useLocation();
    const intl = useIntl();
    const {
      authStore: { isAuthenticated, refreshIsAuthenticated, isWizardCompleted },
      agreementStore: { getAgreements, currentEstate },
      currentUserStore: {
        currentUser,
        getCurrentUser,
        state: currentUserStoreState,
      },
      newsStore: { getExceptionNotices },
      commonDataStore: { settings, getSettings },
      messageStore: {
        getUnreadCount,
        unreadCount,
        state: messageStoreState,
        subscribe,
        hasSubscribed,
      },
    }: {
      authStore: IAuthStore;
      agreementStore: IAgreementStore;
      currentUserStore: ICurrentUserStore;
      newsStore: INewsStore;
      messageStore: IMessageStore;
      commonDataStore: ICommonDataStore;
    } = useStores();

    const pathWithoutLang = getPathWithoutLangPrefix(location.pathname);
    const { locale } = intl;

    // Adjusted paths for authentication
    const authPathArray = [PATHS.auth.login, PATHS.auth.accountSetup];
    const isAuthPath = authPathArray.some((path) => {
      return pathWithoutLang.startsWith(path);
    });

    const isWizardPath = pathWithoutLang.startsWith('/wizard');
    const isPasswordChange = pathWithoutLang.startsWith('/change-password');

    useEffect(() => {
      getSettings();
    }, [getSettings]);

    useEffect(() => {
      if (isAuthenticated === true && !pushInitialized) {
        PushService.askPermission();
        setPushInitialized(true);
      }
    }, [setPushInitialized, pushInitialized, isAuthenticated]);

    useEffect(() => {
      if (settings?.maintenanceMode) return;
      setCurrentHref(location && location.pathname);

      if (isAuthenticated === undefined) refreshIsAuthenticated();

      if (isAuthenticated === false && !isAuthPath && !isPasswordChange) {
        navigate(`/${locale}/login`);
      }

      if (isAuthenticated) {
        if (isAuthPath && !isWizardPath && !isPasswordChange) {
          navigate(`/${locale}`);
        }

        if (!currentUser && currentUserStoreState === 'Idle' && isAuthenticated) {
          getCurrentUser();
        }

        if (!unreadCount && messageStoreState === 'Idle') {
          getUnreadCount();
        }
      }
    }, [
      isAuthenticated,
      isAuthPath,
      isPasswordChange,
      isWizardPath,
      locale,
      location,
      refreshIsAuthenticated,
      settings,
      currentUser,
      currentUserStoreState,
      getCurrentUser,
      unreadCount,
      messageStoreState,
      getUnreadCount,
    ]);

    useEffect(() => {
      if (isAuthenticated && !isWizardCompleted && !isWizardPath) {
        navigate(`/${locale}/wizard`);
      }
    }, [isAuthenticated, isWizardCompleted, isWizardPath, locale]);

    useEffect(() => {
      if (isAuthenticated) {
        getAgreements();
      }
    }, [isAuthenticated, getAgreements]);

    useEffect(() => {
      getExceptionNotices();
    }, [getExceptionNotices, currentEstate]);

    useEffect(() => {
      if (!hasSubscribed) subscribe(0);
    }, [hasSubscribed, subscribe]);

    useEffect(() => {
      if (settings?.maintenanceMode) navigate(`/${locale}/maintenance`);
    }, [settings, locale]);

    const renderContent = () => {
      if (customLayout) return children;

      if (twoColumnLayout) return <TwoColumnLayout>{children}</TwoColumnLayout>;

      return (
        <>
          <LayoutHeader
            currentHref={currentHref}
            heroImage={heroImage}
            heroContent={heroContent}
            extraShortcuts={extraShortcuts}
            afterHeader={afterHeader}
          />
          <Content
            // @ts-ignore
            maxWidth={maxWidth}
          >
            {children}
          </Content>
          <Footer />
        </>
      );
    };

    const renderHeadlessContent = () => {
      if (customLayout) return children;
      if (twoColumnLayout) return <TwoColumnLayout>{children}</TwoColumnLayout>;

      return (
        <>
          <LayoutEventHeader
            currentHref={currentHref}
            heroImage={heroImage}
            heroContent={heroContent}
            extraShortcuts={extraShortcuts}
            afterHeader={afterHeader}
          />
          <Content
            // @ts-ignore
            maxWidth={maxWidth}
          >
            {children}
          </Content>
        </>
      );
    };

    const conditionalRender = () => {
      const isHeadless =
        typeof window !== 'undefined' && window.location.search.includes('event');

      if (isHeadless) {
        return renderHeadlessContent();
      } else {
        return renderContent();
      }
    };

    return (
      <Box key={key}>
        <ThemeProvider theme={theme()}>
          <GlobalStyles />
          <Wrapper>
            {conditionalRender()}
            <Seo />
            <ToastProvider />
          </Wrapper>
        </ThemeProvider>
      </Box>
    );
  }
);

export { Layout };